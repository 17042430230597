<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary mt-3">
            {{
              $t(
                "Pages.Send_Report.Title.did_encounter_problem"
              )
            }}
          </h2>
          <b-card-text class="mb-3 mt-2">
            {{
              $t(
                "Pages.Send_Report.Title.did_encounter_problem_txt"
              )
            }}
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group>
            <b-input-group>
              <b-form-textarea
                v-model="text"
                rows="10"
                name="name"
                type="text"
                :placeholder="$t('Base.Placeholder.txt_problem')"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group class="w-50 d-flex">
            <b-input-group>
              <b-form-file
                accept=".jpg, .png,"
                v-model="image"
                name="name"
                :placeholder="$t('Base.Placeholder.img_problem')"
              />
              <b-button
                @click="sendReport"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mx-3 w-25"
              >
                {{ $t("Pages.Send_Report.Button.send_report") }}
              </b-button>
            </b-input-group>
          </b-form-group>
          <b-card-text
            >{{ $t("Pages.Account.Title.auth_format") }}: JPG, PNG
          </b-card-text>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BFormFile,
  BImgLazy,
  BModal,
  BCard,
  BCardBody,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BLink,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  data() {
    return {
      image: null,
      text: "",
    };
  },
  components: {
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormFile,
    BImgLazy,
    AppCollapseItem,
    AppCollapse,
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  methods: {
    sendReport() {
      if (this.text != "") {
        const data = new FormData();
        if (this.image != null) {
          data.append("image", this.image);
        }
        data.append("text", this.text);
        this.$http
          .post(`/api/report/panel`, data)
          .then((resp) => {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t(
                "Base.Alert.success_report_submit"
              ),
              "success"
            );
            this.text = "";
            this.image = null;
          })
          .catch(() => {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t(
                "Base.Alert.complete_report_txt"
              ),
              "danger"
            );
          });
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t(
            "Base.Alert.complete_report_txt"
          ),
          "danger"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.content-right {
  width: 100% !important;
}
</style>
